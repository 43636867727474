
export function init() {
	document.querySelector("[href='#overlay']").onclick = function (e) {
		e.preventDefault();
		document.querySelector("#overlay").classList.toggle("visible");
	};
	document.querySelector("#overlay").onclick = function (e) {
		this.classList.toggle("visible");
	}
}
