/*
 * import
 *
 */
import * as sticky from "./sticky.mjs";
import * as offcanvas from "./offcanvas.mjs";
// import * as smoothScroll from "./smoothScroll.mjs";


/*
 * use
 *
 */
sticky.init();
offcanvas.init();



document.querySelectorAll(".services__nav a").forEach(ele => ele.onclick = doclick);

function doclick(e) {
	e.preventDefault();
	scroll_to(e.target.hash);
}

function scroll_to(selector) {
	// e.preventDefault();
	console.log(selector);
	const target = document.querySelector(selector);
	const targetY = target.offsetTop;
	const headerY = document.querySelector(".navbar").scrollHeight;
	const vertical = targetY - headerY;
	// const $header = $(".ca-fortune__header")[0];
	// const $target = $($(this).attr("href"));
	// const vertical = $target.offset().top - $header.height();

	window.scrollTo({
		top: vertical,
		left: 0,
		behavior: "smooth"
	});
}

if (window.location.hash) {
	window.onload = () => scroll_to(window.location.hash + "_section");
}
