export function init() {
	// When the user scrolls the page, execute myFunction
	window.onscroll = function () { myFunction() };
	// Get the navbar
	const navbar = document.querySelector(".navbar");
	function myFunction() {
		if (window.scrollY > 0) {
			navbar.classList.add("sticky")
		} else {
			navbar.classList.remove("sticky");
		}
	}
}
